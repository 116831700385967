"use strict";

function toggleFullScreen() {
    document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? document.cancelFullScreen ? document.cancelFullScreen() : document.mozCancelFullScreen ? document.mozCancelFullScreen() : document.webkitCancelFullScreen && document.webkitCancelFullScreen() : document.documentElement.requestFullscreen ? document.documentElement.requestFullscreen() : document.documentElement.mozRequestFullScreen ? document.documentElement.mozRequestFullScreen() : document.documentElement.webkitRequestFullscreen && document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
}
$(window).on("load", function () {
        function a(a, b) {
            $.growl({
                message: a
            }, {
                type: b,
                allow_dismiss: !1,
                label: "Cancel",
                className: "btn-xs btn-inverse",
                placement: {
                    from: "bottom",
                    align: "right"
                },
                delay: 2500,
                animate: {
                    enter: "animated fadeInRight",
                    exit: "animated fadeOutRight"
                },
                offset: {
                    x: 30,
                    y: 30
                }
            })
        }
        var b = $(window);
        $(".loader-bar").animate({
            width: b.width()
        }, 2e3), setTimeout(function () {
            for (; $(".loader-bar").width() == b.width();) {
                removeloader();
                break
            }
        }, 2500)
    }), $(document).ready(function () {
        $(".designation").on("click", function () {
            $(".extra-profile-list").slideToggle()
        });
        var a = $(window).height() - 50;
        // $(".main-friend-list ").slimScroll({
        //     height: a,
        //     allowPageScroll: !1,
        //     wheelStep: 5,
        //     color: "#1b8bf9"
        // }), 
        $("#search-friends").on("keyup", function () {
            var a = $(this).val().toLowerCase();
            $(".friendlist-box .media-body .friend-header").each(function () {
                var b = $(this).text().toLowerCase();
                $(this).closest(".friendlist-box")[b.indexOf(a) !== -1 ? "show" : "hide"]()
            })
        }), $(".displayChatbox").on("click", function () {
            var a = {
                direction: "right"
            };
            $(".showChat").toggle("slide", a, 500)
        }), $(".friendlist-box").on("click", function () {
            var a = {
                direction: "right"
            };
            $(".showChat_inner").toggle("slide", a, 500)
        }), $(".back_chatBox").on("click", function () {
            var a = {
                direction: "right"
            };
            $(".showChat_inner").toggle("slide", a, 500), $(".showChat").css("display", "block")
        }), $("[data-toggle='utility-menu']").on("click", function () {
            return $(this).next().slideToggle(300), $(this).toggleClass("open"), !1
        })
    }), $('[data-toggle="tooltip"]').tooltip(), $('[data-toggle="popover"]').popover({
        animation: !0,
        delay: {
            show: 100,
            hide: 100
        }
    }), Waves.init(), Waves.attach(".flat-buttons", ["waves-button"]), Waves.attach(".float-buttons", ["waves-button", "waves-float"]), Waves.attach(".float-button-light", ["waves-button", "waves-float", "waves-light"]), Waves.attach(".flat-buttons", ["waves-button", "waves-float", "waves-light", "flat-buttons"]), $.pushMenu = {
        activate: function (a) {
            $(a).on("click", function (a) {
                a.preventDefault(), $(window).width() > 767 ? $("body").hasClass("sidebar-collapse") ? $("body").removeClass("sidebar-collapse").trigger("expanded.pushMenu") : $("body").addClass("sidebar-collapse").trigger("collapsed.pushMenu") : $("body").hasClass("sidebar-open") ? $("body").removeClass("sidebar-open").removeClass("sidebar-collapse").trigger("collapsed.pushMenu") : $("body").addClass("sidebar-open").trigger("expanded.pushMenu"), $("body").hasClass("fixed") && $("body").hasClass("sidebar-mini") && $("body").hasClass("sidebar-collapse") && ($(".sidebar").css("overflow", "visible"), $(".main-sidebar").find(".slimScrollDiv").css("overflow", "visible")), $("body").hasClass("only-sidebar") && ($(".sidebar").css("overflow", "visible"), $(".main-sidebar").find(".slimScrollDiv").css("overflow", "visible"))
            }), $(".content-wrapper").on("click", function () {
                $(window).width() <= 767 && $("body").hasClass("sidebar-open") && $("body").removeClass("sidebar-open")
            })
        }
    }, $.tree = function (a) {
        var b = 200;
        $(document).on("click", a + " li a", function (a) {
            var c = $(this),
                d = c.next();
            if (d.is(".treeview-menu") && d.is(":visible")) d.slideUp(b, function () {
                d.removeClass("menu-open")
            }), d.parent("li").removeClass("active");
            else if (d.is(".treeview-menu") && !d.is(":visible")) {
                var e = c.parents("ul").first(),
                    f = e.find("ul:visible").slideUp(b);
                f.removeClass("menu-open");
                var g = c.parent("li");
                d.slideDown(b, function () {
                    d.addClass("menu-open"), e.find("li.active").removeClass("active"), g.addClass("active")
                })
            }
            d.is(".treeview-menu") && a.preventDefault()
        })
    }, $.tree(".sidebar"), $.pushMenu.activate("[data-toggle='offcanvas']"),
    // function () {
    //     var a, b = document.getElementById("morphsearch"),
    //         // c = b.querySelector("input.morphsearch-input"),
    //         // d = b.querySelector("span.morphsearch-close"),
    //         // e = a = !1,
    //         // f = morphsearch.querySelector(".morphsearch-form"),
    //         g = function (a) {
    //             if ("focus" === a.type.toLowerCase() && e) return !1;
    //             morphsearch.getBoundingClientRect();
    //             e ? (classie.remove(b, "open"), setTimeout(function () {
    //                 classie.add(b, "hideInput"), setTimeout(function () {
    //                     classie.add(f, "p-absolute"), classie.remove(b, "hideInput"), c.value = ""
    //                 }, 300)
    //             }, 500), c.blur()) : (classie.remove(f, "p-absolute"), classie.add(b, "open")), e = !e
    //         };
    //     c.addEventListener("focus", g), d.addEventListener("click", g), document.addEventListener("keydown", function (a) {
    //         var b = a.keyCode || a.which;
    //         27 === b && e && g(a)
    //     });
    //     var h = document.getElementById("morphsearch-search");
    //     // h.addEventListener("click", g), b.querySelector('button[type="submit"]').addEventListener("click", function (a) {
    //     //     a.preventDefault()
    //     // })
    // }();
//var ost = 0;
$(window).scroll(function () {
    var a = $(window);
    $(window).innerHeight();
    if (a.width() <= 767) {
        var b = $(this).scrollTop();
        0 == b ? $(".showChat").removeClass("top-showChat").addClass("fix-showChat") : b > ost && $(".showChat").removeClass("fix-showChat").addClass("top-showChat"), ost = b
    }
}), $(document).ready(function () {
    $(".dropup-mega, .dropup").hover(function () {
        $(this).children(".dropdown-menu");
        $(this).toggleClass("open")
    })
});